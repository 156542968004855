import { useEffect, useRef } from 'react';
import { Navbar, Footer, Card, Header } from '../components';
import { isMobile } from 'react-device-detect';

export function LandingPage () {
    const headerHeight = '250px';

    const promoReferral = useRef(null);


    useEffect(function () {
        const video = promoReferral.current;

        if (video) {
            video.muted = true;

            video.play().catch(error => {
                console.error('Autoplay failed:', error);
            });
        }
    }, []);

    return (
        <div>
            <Navbar />

            {/* <video width="100%" ref={promoReferral}>
                <source src="/promos/3.mp4" type="video/mp4" />
            </video> */}


            <div className='container' style={{ marginTop: '35px' }}>
                <Header height='100%' width={isMobile ? '100%' : '60%'} />
            </div>

            <div className='container' style={{ marginTop: '35px' }}>
                <h1 className='h3' style={{ paddingBottom: '25px' }}>Site Directory:</h1>

                <div className="row">
                    <div className="col-md-4">
                        <Card title='Upcoming & Past Events' href='/events' />
                    </div>
                    <div className="col-md-4">
                        <Card title='Purchase Tickets' href='/tickets' />
                    </div>
                    <div className="col-md-4">
                        <Card title='Media & Gallery Items' href='/gallery' />
                    </div>
                    {/* <div className="col-md-4">
                        <Card title='Fighters List' href='/fighters' />
                    </div>
                    <div className="col-md-4">
                        <Card title='Rules List' href='/rules' />
                    </div> */}
                    {/* <div className="col-md-4">
                        <Card title='Sponsors List' href='/sponsors' />
                    </div> */}
                    <div className="col-md-4">
                        <Card title='Make a Donation' href='/donate' />
                    </div>
                </div>
            </div >

            <Footer />
        </div >
    );
}