import {
	SearchPage,
	EventsPage,
	GalleryPage,
	SponsorsPage,
	TicketsPage,
	LandingPage,
	NotFoundPage,
	DonationsPage,
	PrintTicketsPage,
	ContactPage,
} from './index'

export const Routes = [
	{
		path: '/',
		label: 'Landing',
		element: <LandingPage />,
		errorElement: <NotFoundPage />,
		hidden: true,
	},
	{
		path: '/events',
		label: 'Events',
		element: <EventsPage />,
	},
	// {
	// 	path: '/tickets',
	// 	label: 'Purchase Tickets',
	// 	element: <TicketsPage />,
	// 	rightAligned: true,
	// },
	// {
	// 	path: '/search',
	// 	icon: <i className='fas fa-search'></i>,
	// 	element: <SearchPage />,
	// 	rightAligned: true,
	// },
	{
		path: '/gallery',
		label: 'Gallery',
		element: <GalleryPage />,
	},
	// {
	// 	path: '/fighters',
	// 	element: <FightersListPage />,
	// },
	// {
	// 	path: '/rules',
	// 	element: <RulesPage />,
	// },
	// {
	// 	path: '/sponsors',
	// 	label: 'Sponsors',
	// 	element: <SponsorsPage />,
	// },
	{
		path: '/donate',
		label: 'Donate',
		element: <DonationsPage />,
	},
	// {
	// 	path: '/contact',
	// 	label: 'Contact',
	// 	element: <ContactPage />,
	// },
	{
		path: '/tickets/print',
		label: 'Print Tickets',
		element: <PrintTicketsPage />,
		hidden: true,
	},
]
