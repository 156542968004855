import { useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';

export function Header ({ height, width }) {
    const promoReferral = useRef(null);

    const slides = 2;

    const [Slide, setSlide] = useState(0);

    function slideUp () {
        if (Slide + 1 >= slides) {
            setSlide(0);
        } else {
            setSlide(i => i + 1);
        }
    }

    function slideDown () {
        if (Slide - 1 <= -1) {
            setSlide(slides - 1);
        } else {
            setSlide(i => i - 1);
        }
    }

    function leftClick (e) {
        e.preventDefault();
        slideDown();
    }

    function rightClick (e) {
        e.preventDefault();
        slideUp();
    }

    useEffect(function () {
        if (Slide == 0) return;

        setTimeout(() => setSlide(i => i--), 5000);
    }, [Slide]);

    useEffect(function () {
        if (Slide != 0) return;

        const video = promoReferral.current;

        if (video) {
            video.muted = true;

            video.volume = 0.175;

            video.play();
        }
    }, [Slide]);

    setTimeout(() => {
        window.onload = () => {
            setVideoMuted(false);
        };
    }, []);

    const [VideoMuted, setVideoMuted] = useState(false);

    useEffect(function () {
        if (!promoReferral) return;

        promoReferral.current.muted = VideoMuted;
    }, [VideoMuted, promoReferral]);

    return (
        <>
            <style>
                {`
                .outer {
                    position: absolute;
                    top: 0px;
                    height: 300px;
                    width: 30px;
                }
                .inner {
                    transform: translateX(-50%) translateY(-50%) rotate(90deg);
                    margin-left: 10px;
                    position: absolute;
                    top: 50%;
                    text-align: center;
                }
                `}
            </style>

            {Slide == 0 && (
                <div
                    style={{
                        width: isMobile ? '100%' : "60%",
                        position: 'relative',
                        margin: 'auto'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: "8px",
                            backgroundColor: 'black',
                            color: '#eee',
                            display: 'flex',
                        }}
                    >
                        <video width="100%" ref={promoReferral} onEnded={() => setSlide(i => i++)}>
                            <source src="/promos/3.mp4" type="video/mp4" />
                        </video>
                    </div>

                    {/* <div
                    style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: "8px",
                        backgroundColor: 'rgba(0, 0, 0, 0.35)',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                    }}
                /> */}

                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', color: '#eee' }}>
                            <a href='#' onClick={leftClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                <div>
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        <i className="fas fa-chevron-left"></i>
                                    </h1>
                                </div>
                            </a>

                            <div style={{ width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {/* <div className="text-center">
                                <h1 className="h5">
                                    American Steelfighting League Presents
                                </h1>

                                <h1 className="h2">
                                    Husaria Cup
                                </h1>

                                <h1 className="h5">
                                    Aug. 10 at the Split Rock Hotel
                                </h1>

                                <Button variant='secondary' href='/tickets' style={{ marginTop: '5px' }}>
                                    Purchase Tickets
                                </Button>
                            </div> */}
                            </div>

                            <a href='#' onClick={rightClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                <div>
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        <i className="fas fa-chevron-right"></i>
                                    </h1>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            right: '20px',
                            height: '35px',
                            width: '50px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >

                        <div style={{ textAlign: 'center', marginTop: 'auto' }}>
                            <a
                                href='#'
                                onClick={() => setVideoMuted(b => !b)}
                                style={{
                                    color: "#eee",
                                    margin: "0",
                                    padding: '0',
                                    fontSize: '25px'
                                }}
                            >
                                <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                    <i className={!VideoMuted ? "fas fa-volume-up" : 'fas fa-volume-mute'}></i>
                                </h1>
                            </a>
                        </div>
                    </div >
                </div >
            )}

            {Slide == 1 && (
                <div
                    style={{
                        height: isMobile ? '250px' : '200px',
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: "8px",
                            backgroundColor: 'black',
                            color: '#eee',
                            display: 'flex',
                            backgroundImage: "url(\"/pictures/15.jpg\")",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '50% 20%',
                        }}
                    />

                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: "8px",
                            backgroundColor: 'rgba(0, 0, 0, 0.35)',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', color: '#eee' }}>
                            <a href='#' onClick={leftClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                <div>
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        <i className="fas fa-chevron-left"></i>
                                    </h1>
                                </div>
                            </a>

                            <div style={{ width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="text-center">
                                    <h1 className="h5">
                                        American Steelfighting League Presents
                                    </h1>

                                    <h1 className="h2">
                                        Husaria Cup
                                    </h1>

                                    <h1 className="h5">
                                        Aug. 10 at the Split Rock Hotel
                                    </h1>

                                    <OverlayTrigger
                                        placement="left"
                                        overlay={<Tooltip>Event closed.</Tooltip>}
                                    >
                                        <Button variant='secondary' style={{ marginTop: '5px', cursor: 'not-allowed' }}>
                                            Purchase Tickets
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>

                            <a href='#' onClick={rightClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                                <div>
                                    <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                        <i className="fas fa-chevron-right"></i>
                                    </h1>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};