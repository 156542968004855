import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navbar, Footer } from "../components";
import { isMobile } from 'react-device-detect';

export function EventsPage () {
    return (
        <div>
            <Navbar />

            <Container>
                <div style={{ paddingTop: '30px', paddingBottom: '45px' }}>
                    <h1 className="h1">Events</h1>

                    <div style={{ padding: "25px 0 0 0" }}>
                        <h1 className="h2">2024 Events</h1>

                        <div style={{ width: "100%", backgroundColor: 'rgb(33, 37, 41)', height: "4px", borderRadius: '4px' }} />

                        <div style={{ padding: '15px', display: !isMobile ? 'flex' : null, backgroundColor: 'rgb(49, 49, 49)', color: '#eee', marginTop: '15px', borderRadius: '6px' }}>
                            <a href='/flyers/split-rock.png' target="_blank">
                                <img src='/flyers/split-rock.png' style={{ width: isMobile ? '100%' : '285px' }} />
                            </a>

                            <div style={{ padding: '25px', paddingLeft: isMobile ? 0 : '25px' }}>
                                <h1 className="h3">Husaria Cup</h1>

                                <h1 className="h4" style={{ paddingTop: '20px' }}>Date: August 10, 2024</h1>

                                <h1 className="h4" style={{ paddingTop: '15px' }}>Location: Split Rock Hotel, Lake Harmony P.A.</h1>

                                <h1 className="h4" style={{ paddingTop: '15px' }}>Time: 6:00 p.m. - 10:00 p.m.</h1>

                                <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip>Event closed.</Tooltip>}
                                >
                                    <Button variant='secondary' style={{ marginTop: '5px', cursor: 'not-allowed' }}>
                                        Purchase Tickets
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Footer />
        </div>
    );
}