import { useEffect } from "react";

export function PrintTicketsPage () {
    useEffect(function () {
        window.print();
    }, []);

    return (
        <div>
            <div
                style={{
                    width: "100%",
                    backgroundColor: 'rgb(49, 49, 49)',
                    padding: '0',
                    margin: '0',
                    borderRadius: '8px 8px 0 0'
                }}
            >
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <img
                        src="/logo192.png"
                        style={{ width: '140px' }}
                    />

                    <h1 style={{ color: "#eee", padding: '0', margin: '0', color: 'black' }}>
                        American Steelfighting League
                    </h1>

                    <h1 style={{ color: "#eee", padding: '0', margin: '0', color: 'black' }}>
                        Husaria Cup 2024
                    </h1>

                    <p style={{ color: "#eee", padding: '0', margin: '0', fontSize: '18px', color: 'black', paddingTop: '10px' }}>
                        Aug. 10 at the Split Rock Hotel
                    </p>
                </div>
            </div>

            <div
                style={{ width: "100%", backgroundColor: 'rgb(128, 128, 128)', textAlign: 'center', borderRadius: '0 0 8px 8px' }}
            >
                <div>
                    <u style={{ color: 'black' }}>
                        <h1>Tickets</h1>
                    </u>

                    <img
                        src="https://i.imgur.com/XavhRtL.png"
                        style={{ width: '240px' }}
                    />
                </div>

                <div style={{ paddingTop: "20px" }}>
                    <u style={{ color: 'black' }}>
                        <h1>Directions</h1>
                    </u>

                    <a href="https://maps.app.goo.gl/nyxvRBpHW2Twf7zo8" style={{ color: 'black' }}>
                        <p style={{ fontSize: '22px', margin: '0', padding: "0", paddingTop: "10px" }}>
                            Pocono Mountains, 428 Moseywood Rd, Lake Harmony, PA 18624
                        </p>
                    </a>
                </div>

                <div style={{ paddingTop: '20px', paddingBottom: "20px", textAlign: 'center' }}>
                    <u style={{ color: 'black' }}>
                        <h1>Event Information</h1>
                    </u>

                    <p style={{ width: "80%", margin: 'auto', padding: '0', fontSize: '16px', color: 'black' }}>
                        Lorem dolor sit amet, consectetur adipiscing elit. Pellentesque vel mi ut
                        velit tempor aliquam eget eget enim. Proin cursus eleifend pretium. Aliquam cursus
                        pellentesque interdum. Vivamus placerat id leo a pellentesque. Vivamus a congue urna,
                        sed porta eros. Etiam finibus magna et est aliquam, sed semper libero facilisis.
                        Donec lectus lorem, rhoncus vitae quam eget, vulputate gravida elit. Praesent ultricies
                        eros id velit condimentum, eu ultrices nisl consequat.
                    </p>
                </div>
            </div>
        </div>
    );
}